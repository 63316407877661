<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="500">
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Add New Item</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body d-flex flex-column h-250px scroll-y mx-5 mx-xl-15 py-7"
        >
          <div>
            <div class="h-50px">
              <v-text-field
                v-model="formData.systemCode"
                label="System Code"
                clearable
                outlined
                dense
                :error-messages="systemCodeErrors"
                @input="$v.formData.systemCode.$touch()"
                @blur="$v.formData.systemCode.$touch()"
              ></v-text-field>
            </div>
            <div
              class="col-12 col-sm-6 col-md-4 h-75px d-flex align-start ma-0 py-0"
            >
              <h5 class="pr-4 pt-5 font-weight-regular">
                {{ this.formData.status ? "Enable" : "Disable" }}
              </h5>
              <v-switch
                v-model="formData.status"
                hide-details
                dense
                :true-value="1"
                :false-value="0"
              ></v-switch>
            </div>
          </div>

          <div class="d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetFormData"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitForm"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "AddSku",
  mixins: [validationMixin],
  validations: {
    formData: {
      systemCode: { required },
    },
  },
  props: ["actionFunction", "customer"],
  data: () => ({
    dialog: false,
    isLoading: false,
    search: null,
    count: null,
    entries: [],
    formData: {
      systemCode: null,
      status: 1,
      is_from_server: 0,
    },
  }),
  methods: {
    async isSkuValid() {
      this.$store.commit(SET_PAGE_LOADING, true);
      // let customer = this.$store.getters.currentUser.data.id;
      // if (!this.$store.getters.isClient) {
      //   customer = this.$store.getters.getSelectedCustomer;
      // }
      await ApiService.post("/fulfillment/skus/validate_sku_code", {
        q: this.formData.systemCode,
        customer: this.customer,
      })
        .then((response) => {
          if (response.data.success) {
            this.actionFunction(this.formData);
            this.toggleModal();
            this.$store.commit(SET_PAGE_LOADING, false);
          } else {
            Swal.fire({
              title: "Warning",
              text: `${response.data.message[0]}!`,
              icon: "warning",
              showConfirmButton: true,
              timer: 2500,
            });
            this.$store.commit(SET_PAGE_LOADING, false);
          }
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isSkuValid();
    },
    resetFormData() {
      this.$v.$reset();
      this.formData = {
        systemCode: null,
        status: 1,
        is_from_server: 0,
      };
    },
    toggleModal() {
      if (!this.dialog) {
        this.resetFormData();
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    systemCodeErrors: function() {
      return this.handleFormValidation("systemCode", this);
    },
  },
};
</script>
