<template>
  <v-card class="mt-4">
    <v-toolbar color="primary h5 text-white">
      <div class="w-100 d-flex align-items-center justify-space-between">
        <div>
          {{ itemprop.title }}
        </div>
        <div class="bg-white px-3 rounded">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            clearable
            single-line
            hide-details
            class="min-w-250px"
            @keyup="debounceSearch"
            @click:clear="debounceSearch"
          />
        </div>
      </div>
    </v-toolbar>

    <v-card-text class="pa-4">
      <v-data-table
        disable-sort
        :headers="itemprop.data.header"
        :items="getItems"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        class="elevation-0"
        :footer-props="{ 'items-per-page-options': item_per_page_list }"
      >
        <template
          v-for="head in itemprop.data.header"
          v-slot:[`item.`+head.value]="{ item, header, value }"
        >
          <template v-if="head.value == 'last_status'">
            <StatusColumn :key="head.value" :value="value" />
          </template>
          <template v-else>
            <span :key="head.value" class="second-text"> {{ value }}</span>
          </template>
        </template>
        <template
          #item.actions="{ item }"
          v-if="itemprop.type === 'datatable_with_search_and_actions'"
        >
          <v-menu left transition="scale-transition" offset-y bottom rounded>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                v-on="on"
                class="btn btn-light-light btn-hover-info show poppins min-w-75px"
              >
                Actions
                <span class="svg-icon svg-icon-5 m-0">
                  <v-icon small>mdi-chevron-down</v-icon>
                </span>
              </a>
            </template>
            <v-card class="poppins py-1" elevation="1">
              <template v-for="menuItem in item?.actions">
                <div :key="menuItem.id">
                  <v-btn
                    text
                    class="w-100 text-center"
                    :disabled="!menuItem.is_visible"
                    @click="() => !loading && handleActionClick(menuItem, item)"
                    >{{ menuItem.title }}</v-btn
                  >
                </div>
              </template>
            </v-card>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>
    <CancelDetails ref="cancelSku" :getDataFromApi="getDataFromApi" />
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
import StatusColumn from "@/own/components/datatable/StatusColumn.vue";

import Swal from "sweetalert2";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import CancelDetails from "@/own/components/fulfillment/orders/editSKUDetails/CancelDetails.vue";
export default {
  name: "PopUpDataTableWithSearch",
  components: { CancelDetails, StatusColumn },
  props: ["itemprop"],
  data: () => ({
    debounce: null,
    search: "",
    total: 0,
    options: {},
    loading: true,
    items: [],
    item_per_page_list: [20, 30, 40, 50],
  }),
  computed: {
    getItems() {
      return this.items;
    },
  },
  methods: {
    handleActionClick(menuItem, item) {
      if (menuItem.name == "cancel_parcel") {
        this.$refs.cancelSku.toggleModal(
          {
            item: item,
            type: "pack",
            id: item.packaging_task_id,
            qty: item.total_packed_qty,
          },
          { package_code: item.package_code }
        );
      } else if (menuItem.name == "cancel_dispatch") {
        this.$refs.cancelSku.toggleModal(
          {
            item: item,
            type: "dispatch",
            id: item.dispatch_task_id,
            qty: item.dispatched_qty,
          },
          { vehicle_no: item.vehicle_no }
        );
      } else if (menuItem.title.toLowerCase().includes("cancel")) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          // confirmButtonText: "Yes, cancel it!",
        }).then((result) => {
          if (result.isConfirmed) {
            // this.loading = true;
            this.$store.commit(SET_PAGE_LOADING, true);
            ApiService.post(menuItem.url, { id: menuItem.id })
              .then(() => {
                this.getDataFromApi();
                Swal.fire(
                  "Success!",
                  "Your request has been successfully submitted.",
                  "success"
                );
              })
              .finally(() => {
                // this.loading = false;

                this.$store.commit(SET_PAGE_LOADING, false);
              });
          }
        });
      } else {
        // this.loading = true;
        this.$store.commit(SET_PAGE_LOADING, true);
        ApiService.post(menuItem.url, { id: menuItem.id })
          .then(() => {
            this.getDataFromApi();
            Swal.fire(
              "Success!",
              "Your request has been successfully submitted.",
              "success"
            );
          })
          .finally(() => {
            // this.loading = false;
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
    debounceSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getDataFromApi();
      }, 600);
    },
    getDataFromApi() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;

      let data = {
        id: this.itemprop.id,
        asn_id: this.itemprop.asn_id,
        page: page,
        per_page: itemsPerPage,
      };

      if (this.search) {
        data.q = this.search;
      }

      ApiService.post(this.itemprop.url, data)
        .then((response) => {
          this.total = response.data.data.total;
          this.items = response.data.data.data;

          // this.dialog = false;
        })
        // .catch(() => {
        //   this.dialog = false;
        // })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
};
</script>

<style></style>
